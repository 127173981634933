import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import Autosuggest from "react-autosuggest";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../App";
import notificationSound from "../assets/notification.mp3"; // Ensure you have a notification sound file
import { toast } from "react-toastify";
import CompletedOrderChart from "../components/OwnerView/CompletedOrderChart"; // Import CompletedOrderChart

export const OwnerView = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [completedOrders, setCompletedOrders] = useState([]);
  const [filteredInCompletedOrders, setFilteredInCompleteOrders] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [receivedMessages, setReceivedMessages] = useState([]);
  const token = localStorage.getItem("token");
  const user = localStorage.getItem("logged");
  const url = localStorage.getItem("url");
  const [activeTab, setActiveTab] = useState("total");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const utahTime = (utcTimestamp) => {
    const time = new Date(utcTimestamp);
    time.setHours(time.getHours());
    const formattedUtahTime = time.toLocaleString("en-US", {
      timeZone: "America/Denver",
      hour12: true,
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      timeZoneName: "short",
    });
    return formattedUtahTime;
  };

  useEffect(() => {
    if (!user) {
      navigate("/admin");
    }

    const fetchOrders = async () => {
      try {
        const response = isAdmin
          ? await axios.get(`${url}api/get-completed-order`)
          : await axios.get(`${url}api/get-one-completed-order/${user}`);

        const orderData = response.data;
        setOrders(orderData);
        setFilteredOrders(orderData);
        // console.log(orderData)

        const filteredCompletedOrders = orderData.filter(
          (order) => order.completed
        );
        setCompletedOrders(filteredCompletedOrders);

        const filteredInCompleteOrders = orderData.filter(
          (order) => !order.completed
        );
        setFilteredInCompleteOrders(filteredInCompleteOrders);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    const checkUserRole = async () => {
      try {
        const response = await axios.get(`${url}api/one-user/${user}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const { role } = response.data;
        if (role !== "admin") {
          navigate("/"); 
        }
        setIsAdmin(role === "admin");
      } catch (error) {
        console.error("Error fetching user role:", error);
        setIsAdmin(false);
      }
    };

    checkUserRole();
    fetchOrders();

    const intervalId = setInterval(async () => {
      try {
        const response = isAdmin
          ? await axios.get(`${url}api/get-completed-order`)
          : await axios.get(`${url}api/get-one-completed-order/${user}`);
        const newOrderData = response.data;

        if (newOrderData.length > orders.length) {
          const audio = new Audio(notificationSound);
          audio.play();
          toast.success("You've got an order!");
          setOrders(newOrderData);
          setFilteredOrders(newOrderData);
          setFilteredInCompleteOrders(newOrderData.filter((order) => !order.completed));
        }
      } catch (error) {
        console.error("Error during polling:", error);
      }
    }, 30000);

    return () => clearInterval(intervalId);
  }, [
    isAdmin,
    user,
    url,
    completedOrders,
    filteredInCompletedOrders,
    filteredOrders,
    token,
  ]);

  const updateCompletedOrder = async (id) => {
    try {
      await axios.post(`${url}api/update-completed-order`, { id: id });
    } catch (error) {
      console.error("Error fetching user role:", error);
    }
  };

  const renderSuggestion = (suggestion) => (
    <div className="text-sm text-gray-800 p-2 cursor-pointer hover:bg-gray-300 rounded border-b">
      <div>{suggestion.name}</div>
      <div className="text-xs text-gray-600">${suggestion.price}</div>
      <div className="text-xs text-gray-500">{suggestion.current_user}</div>
    </div>
  );

  const inputProps = {
    placeholder: "Search Item Name, Date, Customers...",
    value: inputValue,
    onChange: (event, { newValue }) => setInputValue(newValue),
    className:
      "block w-full p-4 text-sm text-gray-900 border border-gray-300 rounded-lg focus:ring-red-500 focus:border-red-500 placeholder-gray-400 focus:ring-1 focus:outline-none",
  };

  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    return orders.filter(
      (order) =>
        order.name?.toLowerCase().includes(inputValue) ||
        order.current_user?.toLowerCase().includes(inputValue)
    );
  };

  return (
    <div className="my-8 px-4">
    
      {/* Directly Passing Completed Orders to the CompletedOrderChart */}
      <CompletedOrderChart completedOrders={completedOrders} />

      <h2 className="text-2xl font-bold mb-4">Orders ({filteredOrders.length})</h2>
      <div className="relative">
        <Autosuggest
          suggestions={getSuggestions(inputValue)}
          onSuggestionsFetchRequested={() => {}}
          onSuggestionsClearRequested={() => {}}
          getSuggestionValue={(suggestion) => suggestion.name}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
        />
        <button
          className="absolute top-0 right-0 bg-red-500 hover:bg-red-600 text-white px-4 py-2 h-[53px] rounded-r-lg"
          onClick={() => setInputValue("")}
        >
          Clear
        </button>
      </div>

      {/* Orders Tab */}
      <div className="flex items-center space-x-4 border w-fit my-4 rounded px-2">
        <div
          className={`flex items-center space-x-4 cursor-pointer ${
            activeTab === "total" ? "text-red-500" : "text-gray-200"
          }`}
          onClick={() => handleTabClick("total")}
        >
          <p className="text-sm">Incomplete Orders:</p>
          <p className="text-3xl font-bold">{filteredInCompletedOrders.length}</p>
        </div>
        <div
          className={`flex items-center space-x-4 cursor-pointer border-l pl-2 ${
            activeTab === "completed" ? "text-red-500 " : "text-gray-200"
          }`}
          onClick={() => handleTabClick("completed")}
        >
          <p className="text-sm">Completed Orders:</p>
          <p className="text-3xl font-bold">{filteredOrders.length}</p>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-4">
        {(activeTab === "total" ? filteredInCompletedOrders : filteredOrders).map(
          (order, idx) => (
            <div key={idx} className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="font-bold text-lg mb-2">{order.name}</h3>
              <p className="text-sm opacity-60">{utahTime(order.createdAt)}</p>
              <p className="text-sm">{order.current_user}</p>
              <p className="underline my-2">Order Details</p>
              {order.meat?.length > 0 && ( <div><span className="font-bold">Meat: </span>{order.meat?.map(meat => (
                <p>-{meat}</p>
              ))}</div>)}
              {order.spiceLevel && <p className="text-sm"><span className="font-bold">Spice: </span>{order.spiceLevel}</p>}
              {order.quantity && <p className="text-sm"><span className="font-bold">Quantity: </span>{order.quantity}</p>}
              {order.size && <p className="text-sm"><span className="font-bold">Size: </span>{order.size}</p>}
              {order.flavors && <p className="text-sm"><span className="font-bold">Flavors: </span>{order.flavors}</p>}
              {order.base && <p className="text-sm"><span className="font-bold">Base: </span>{order.base}</p>}
              {order.carb && <p className="text-sm"><span className="font-bold">Carb: </span>{order.carb}</p>}
              {order.toppings.length > 0 && ( <div><span className="font-bold">Toppings: </span> {order.toppings.map(topping => (
                <p>-{topping}</p>
              ))}</div>)}
              {order.toppingsExtra.length > 0 && ( <div><span className="font-bold">Extra Toppings: </span> {order.toppingsExtra.map(topping => (
                <p>-{topping}</p>
              ))}</div>)}
              <p className="mt-2 text-sm font-semibold text-red-500">
                ${order.price}
              </p>
              <button
                onClick={() => updateCompletedOrder(order._id)}
                className="mt-4 px-4 py-2 text-white bg-green-500 rounded"
              >
                Mark as Completed
              </button>
            </div>
          )
        )}
      </div>
    </div>
  );
};
