import React, { useState, useEffect } from 'react';
import { Banner } from './Banner';

export const MainBanner = () => {
  // State to manage the background image and the text
  const [banner, setBanner] = useState({
    image: "https://images.pexels.com/photos/359993/pexels-photo-359993.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    title: "Fresh Sushi & Boba Tea",
    subtitle: "#1 Rated",
    buttonText: "VIEW MENU",
    buttonLink: "/menu"
  });

  useEffect(() => {
    // List of background images and corresponding text
    const banners = [
      {
        image: "https://images.pexels.com/photos/359993/pexels-photo-359993.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        title: "Fresh Sushi & Boba Tea",
        subtitle: "#1 Rated",
        buttonText: "VIEW MENU",
        buttonLink: "/menu"
      },
      {
        image: "https://images.pexels.com/photos/12100417/pexels-photo-12100417.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        title: "Thai Stir Fry Basil",
        subtitle: "A Taste of Paradise",
        buttonText: "ORDER NOW",
        buttonLink: "/menu"
      },
      {
        image: "https://images.pexels.com/photos/1907228/pexels-photo-1907228.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        title: "Tasty Noodles",
        subtitle: "Satisfy Your Cravings",
        buttonText: "SEE OUR MENU",
        buttonLink: "/menu"
      },
      {
        image: "https://media.istockphoto.com/id/1413040178/photo/boba-milk-tea.jpg?b=1&s=612x612&w=0&k=20&c=Ct2YY_cgxoH9RwLSrz8xA6hAMa7SUqQlxU7CCiuROjE=",
        title: "Boba Tea for Every Mood",
        subtitle: "Sweet and Refreshing",
        buttonText: "TRY IT NOW",
        buttonLink: "/menu"
      },
      {
        image: "https://images.pexels.com/photos/6646369/pexels-photo-6646369.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        title: "Fried Spring Rolls",
        subtitle: "A Perfect Start to Your Day",
        buttonText: "EXPLORE MORE",
        buttonLink: "/menu"
      },
      {
        image: "https://images.pexels.com/photos/29544344/pexels-photo-29544344/free-photo-of-modern-cafe-interior-with-hanging-lights-and-plants.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        title: "We Have More!!",
        subtitle: "Browse Your Favorite",
        buttonText: "EXPLORE MORE",
        buttonLink: "/menu"
      }
    ];

    const timer = setInterval(() => {
      // Set the next banner (both image and text)
      const currentIndex = banners.findIndex(b => b.image === banner.image);
      const nextIndex = (currentIndex + 1) % banners.length;
      setBanner(banners[nextIndex]);
    }, 5000); // Change every 5 seconds

    return () => clearInterval(timer); // Cleanup the interval when the component is unmounted
  }, [banner.image]); // Only track the image to update text and background together

  return (
    <div
      className="relative z-[5000] bg-cover bg-center h-[650px] lg:h-[670px] px-8 transition-all duration-1000"
      style={{ backgroundImage: `url('${banner.image}')` }}
    >
      {/* Darker overlay for better contrast */}
      <div className="absolute inset-0 bg-black/50" />
      
      <div className="relative flex flex-col justify-center text-white max-w-7xl mx-auto h-full z-[400] md:pl-10">
        <h3 className="text-2xl text-orange-600 lg:text-4xl">{banner.subtitle}</h3>
        <h1 className="poppins-bold text-5xl py-2 lg:text-[80px] lg:py-4 lg:max-w-3xl">
          {banner.title}
        </h1>
        <a href={banner.buttonLink} className="bg-orange-600 text-white px-4 py-3 w-fit lg:text-2xl rounded">
          {banner.buttonText}
        </a>
      </div>
      
      {/* Banner Section */}
      <div className="absolute inset-x-0 bottom-0 flex justify-center">
        <div className="relative -bottom-80 lg:-bottom-20 w-[90%] md:w-[700px] lg:w-[1000px] xl:w-[1200px] 2xl:w-[1500px]">
          <Banner />
        </div>
      </div>
    </div>
  );
};
