import React, { useEffect, useState } from "react";

export const Hours = () => {
  const [currentDay, setCurrentDay] = useState("");
  const [currentTime, setCurrentTime] = useState(new Date());
  const [closingSoon, setClosingSoon] = useState(false);

  // Store hours for each day
  const storeHours = {
    Sunday: "Closed",
    Monday: ["11:00 AM", "7:30 PM"],
    Tuesday: ["11:00 AM", "7:30 PM"],
    Wednesday: ["11:00 AM", "7:30 PM"],
    Thursday: ["11:00 AM", "7:30 PM"],
    Friday: ["11:00 AM", "7:30 PM"],
    Saturday: ["11:00 AM", "7:30 PM"],
  };

  // Check if it's closing soon
  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      setCurrentTime(now);

      const day = now.toLocaleDateString("en-US", { weekday: "long" });
      setCurrentDay(day);

      if (storeHours[day] !== "Closed") {
        const [start, end] = storeHours[day];
        const closingTime = parseTime(end);

        // Check if current time is within 30 minutes of closing
        const timeDifference = closingTime - now;
        setClosingSoon(timeDifference > 0 && timeDifference <= 30 * 60 * 1000);
      }
    }, 1000); // Update every second for real-time behavior

    return () => clearInterval(interval);
  }, []);

  // Helper function to parse time strings like "7:30 PM" into Date objects
  const parseTime = (timeStr) => {
    const [time, meridian] = timeStr.split(" ");
    const [hours, minutes] = time.split(":").map(Number);
    const date = new Date();

    date.setHours(meridian === "PM" && hours !== 12 ? hours + 12 : hours);
    date.setMinutes(minutes);
    date.setSeconds(0);

    return date;
  };

  return (
    <div>
      <h1 className="font-semibold">STORE HOURS</h1>
      <ul className="text-gray-500">
        {Object.entries(storeHours).map(([day, hours]) => (
          <li
            key={day}
            className={`flex items-center py-2 ${
              currentDay === day ? "font-bold text-orange-500" : ""
            }`}
          >
            <p>{day}</p>
            <p className="ml-2">
              {hours === "Closed"
                ? "Closed"
                : `${hours[0]} - ${hours[1]}${
                    currentDay === day && closingSoon ? " (Closing Soon)" : ""
                  }`}
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
};
