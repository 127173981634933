import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
} from "recharts";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

 const OwnerView = () => {
  const navigate = useNavigate();
  const [completedOrders, setCompletedOrders] = useState([]);
  const [totalEarningsByMonth, setTotalEarningsByMonth] = useState({});
  const [totalSalesByUser, setTotalSalesByUser] = useState({});
  const [totalSalesAll, setTotalSalesAll] = useState(0);
  const [productSalesByMonth, setProductSalesByMonth] = useState({});
  const [showMoreEarnings, setShowMoreEarnings] = useState(false);
  const [showMoreSalesByUser, setShowMoreSalesByUser] = useState(false);
  const [showMoreProductSales, setShowMoreProductSales] = useState(false);
  const token = localStorage.getItem("token");
  const user = localStorage.getItem("logged");
  const url = localStorage.getItem("url");

  useEffect(() => {
    if (!user) {
      navigate("/admin");
    }

    const fetchCompletedOrders = async () => {
      try {
        const response = await axios.get(`${url}api/get-completed-order`);
        const orderData = response.data;
        console.log(orderData)
        // setCompletedOrders(orderData);

        // Group orders by month, user, and product
        const earningsByMonth = groupByMonth(orderData);
        const salesByUser = groupByUser(orderData);
        const totalSales = calculateTotalSales(orderData);
        const productSales = groupByProduct(orderData);

        setTotalEarningsByMonth(earningsByMonth);
        setTotalSalesByUser(salesByUser);
        setTotalSalesAll(totalSales);
        setProductSalesByMonth(productSales);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchCompletedOrders();

    const intervalId = setInterval(async () => {
      try {
        const response = await axios.get(`${url}api/get-completed-order`);
        const newOrderData = response.data;

        if (newOrderData.length > completedOrders.length) {
          toast.success("You've got an order!");
          setCompletedOrders(newOrderData);
          const earningsByMonth = groupByMonth(newOrderData);
          const salesByUser = groupByUser(newOrderData);
          const totalSales = calculateTotalSales(newOrderData);
          const productSales = groupByProduct(newOrderData);
          setTotalEarningsByMonth(earningsByMonth);
          setTotalSalesByUser(salesByUser);
          setTotalSalesAll(totalSales);
          setProductSalesByMonth(productSales);
        }
      } catch (error) {
        console.error("Error during polling:", error);
      }
    }, 30000);

    return () => clearInterval(intervalId);
  }, [completedOrders, user, url]);

  // Function to group orders by month and calculate total earnings and order count for each month
  const groupByMonth = (orders) => {
    const grouped = {};
    orders.forEach((order) => {
      const month = new Date(order.createdAt).toLocaleString("default", {
        month: "long",
        year: "numeric",
      });
      if (!grouped[month]) {
        grouped[month] = { total: 0, count: 0 };
      }
      grouped[month].total += order.price;
      grouped[month].count += 1;
    });
    return grouped;
  };

  // Function to group orders by user and calculate total earnings for each user
  const groupByUser = (orders) => {
    const grouped = {};
    orders.forEach((order) => {
      const userName = order.name; // Assuming 'name' represents the user
      if (!grouped[userName])
        grouped[userName] = { total: 0, products: [], totalItems: 0 };
      grouped[userName].total += order.price;
      grouped[userName].products.push(order);
      grouped[userName].totalItems += 1; // Count total items ordered by the user
    });
    return grouped;
  };

  // Function to group orders by product name and calculate total sales per product
  const groupByProduct = (orders) => {
    const grouped = {};
    orders.forEach((order) => {
      const productName = order.name; // Assuming 'productName' represents the product name
      if (!grouped[productName]) grouped[productName] = 0;
      grouped[productName] += 1; // Count how many times the product was ordered
    });
    return grouped;
  };

  // Function to calculate total sales for all orders
  const calculateTotalSales = (orders) => {
    return orders.reduce((acc, order) => acc + order.price, 0);
  };

  // Chart data based on completed orders, showing number of orders and total earnings per month
  const chartData = Object.entries(totalEarningsByMonth).map(
    ([month, { total, count }]) => ({
      month,
      total: total || 0, // Ensure total is a valid number
      count: count || 0, // Ensure count is a valid number
    })
  );

  return (
    <div className="my-8 px-4">
      {/* Combined Line and Bar Chart */}
      <ResponsiveContainer width="100%" height={400}>
        <BarChart data={chartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="count" fill="#8884d8" name="Orders" />
          <Line
            type="monotone"
            dataKey="total"
            stroke="#82ca9d"
            name="Total Earnings"
          />
        </BarChart>
      </ResponsiveContainer>

      <div className="grid md:grid-cols-3 lg:grid-cols-4 gap-6">
        {/* Total Earnings by Month */}
        <div className="bg-white p-6 rounded-lg shadow-lg transform transition-all hover:scale-105 duration-300">
          <h3 className="text-lg font-semibold text-gray-800 mb-4 flex items-center space-x-2">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 11l2 2 4-4M5 3h14a2 2 0 012 2v14a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2z" /></svg>
            <span>Total Earnings by Month</span>
          </h3>
          <ul>
            {Object.entries(totalEarningsByMonth)
              .slice(0, showMoreEarnings ? undefined : 4)
              .map(([month, earnings]) => (
                <li key={month} className="text-gray-600">
                  {month}: ${parseFloat(earnings.total || 0).toFixed(2)} ({earnings.count} orders)
                </li>
              ))}
          </ul>
          <button
            onClick={() => setShowMoreEarnings((prev) => !prev)}
            className="text-blue-500 mt-2"
          >
            {showMoreEarnings ? "Show Less" : "Show More"}
          </button>
        </div>

        {/* Total Sales by User */}
        <div className="bg-white p-6 rounded-lg shadow-lg transform transition-all hover:scale-105 duration-300">
          <h3 className="text-lg font-semibold text-gray-800 mb-4 flex items-center space-x-2">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6v6a9 9 0 009 9h6a9 9 0 009-9V6" /></svg>
            <span>Total Sales by User</span>
          </h3>
          <ul>
            {Object.entries(totalSalesByUser)
              .slice(0, showMoreSalesByUser ? undefined : 4)
              .map(([name, { total, products, totalItems }]) => (
                <li key={name} className="text-gray-600 mb-2">
                   {name} - ${total.toFixed(2)} ({totalItems} items ordered) 
                </li>
              ))}
          </ul>
          <button
            onClick={() => setShowMoreSalesByUser((prev) => !prev)}
            className="text-blue-500 mt-2"
          >
            {showMoreSalesByUser ? "Show Less" : "Show More"}
          </button>
        </div>

        {/* Total Sales */}
        <div className="bg-white p-6 rounded-lg shadow-lg transform transition-all hover:scale-105 duration-300">
          <h3 className="text-lg font-semibold text-gray-800 mb-4 flex items-center space-x-2">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 3v12l7-4l7 4V3" /></svg>
            <span>Total Sales</span>
          </h3>
          <p className="text-gray-600">${totalSalesAll.toFixed(2)}</p>
        </div>

        {/* Product Sales by Month */}
        <div className="bg-white p-6 rounded-lg shadow-lg transform transition-all hover:scale-105 duration-300">
          <h3 className="text-lg font-semibold text-gray-800 mb-4 flex items-center space-x-2">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-purple-500" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14M5 16h14M5 8h14" /></svg>
            <span>Product Sales by Month</span>
          </h3>
          <ul>
            {Object.entries(productSalesByMonth)
              .slice(0, showMoreProductSales ? undefined : 4)
              .map(([month, sales]) => (
                <li key={month} className="text-gray-600">
                  {month}: {sales} sales
                </li>
              ))}
          </ul>
          <button
            onClick={() => setShowMoreProductSales((prev) => !prev)}
            className="text-blue-500 mt-2"
          >
            {showMoreProductSales ? "Show Less" : "Show More"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default OwnerView;