import { FaStar } from "react-icons/fa";
import noImage from "../../assets/noImage.png";
import noodlesoup from "../../assets/noodlesoup.png";
import thaibasilstirfry from "../../assets/thaibasilstirfry.jpg";
import califoniaroll from "../../assets/californiaroll.png";
import friedsushiroll from "../../assets/vegasroll.jpg";
import hotcheetossushiroll from "../../assets/hotcheetossushiroll.png";
import redrocksushiroll from "../../assets/redrockroll.png";
import tofupockets from "../../assets/tofupockets.png";
import phillyroll from "../../assets/phillyroll.png";
import sashimi from "../../assets/sashimi.png";
import rainbowroll from "../../assets/rainbowroll.png";
import dragonroll from "../../assets/dragonroll.png";
import crunchyroll from "../../assets/crunchyroll.png";
import spicyroll from "../../assets/spicyroll.png";
import spicymangoroll from "../../assets/spicymangoroll.png";
import dynamiteroll from "../../assets/dynamiteroll.png";
import pokebowl from "../../assets/pokebowl.png";
import coconutchickennoodlesoup from "../../assets/coconutchickennoodlesoup.png";
import teriyakibowl from "../../assets/teriyakibowl.png";
import vegetablespringroll from "../../assets/vegetablespringroll.jpg";
import veggietempura from "../../assets/vegetabletempura.jpg";
import springroll from "../../assets/springroll.png";
import lavenderlemonade from "../../assets/lavenderlemonade.png";
import vietnameseicedcoffee from "../../assets/vietnamessicetea.png";
import milkteas from "../../assets/milktea.png";
import slushy from "../../assets/slushy.png";
import smoothies from "../../assets/smoothies.png";
import fruitteas from "../../assets/fruitteas.png";

const items = [
  {
    id: 1,
    name: "California Roll",
    image: califoniaroll,
    buttonText: "Add To Cart",
    stars: Array(5).fill(<FaStar className="text-yellow-500" />),
    price: 7.49,
    originalPrice: "$15.99",
    category: "Sushi",
    ingredients: [
      "Sushi rice",
      "Crab salad",
      "Avocado",
      "Cucumber",
      "Sesame seeds",
    ],
  },
  {
    id: 2,
    name: "Crunchy Vegas Roll",
    image: friedsushiroll,
    buttonText: "Add To Cart",
    stars: Array(4).fill(<FaStar className="text-yellow-500" />),
    price: 13.99,
    originalPrice: "$6.99",
    category: "Deep Fried",
    ingredients: [
      "tuna",
      "Salmon",
      "Shrimp",
      "Crab",
      "Spicy Sauce",
      "avocado",
      "Chili powder",
      "Sesame Seeds",
      "Fried Onion",
      "Cream cheese",
      "Eel sauce",
    ],
    meats: [
      { name: "Crab Salad" },
      { name: "Shrimp Tempura" },
      { name: "Salmon" },
      { name: "Tuna" },
    ],
  },
  {
    id: 3,
    name: "Red Rock Roll",
    image: redrocksushiroll,
    buttonText: "Add To Cart",
    stars: Array(3).fill(<FaStar className="text-yellow-500" />),
    price: 12.99,
    originalPrice: "$10.50",
    category: "Sushi",
    ingredients: [
      "tuna",
      "Fish Egg",
      "Green Onion",
      "crab salad",
      "Cucumber",
      "Avocado",
    ],
  },
  {
    id: 4,
    name: "Hot Cheetos Roll",
    image: hotcheetossushiroll,
    buttonText: "Add To Cart",
    stars: Array(4).fill(<FaStar className="text-yellow-500" />),
    price: 8.99,
    originalPrice: "$8.99",
    category: "Sushi",
    ingredients: [
      "Nori (seaweed)",
      "Sushi rice",
      "Crab Salad",
      "Avocado",
      "Cream Cheese",
    ],
  },
  {
    id: 5,
    name: "Tofu Pockets",
    image: tofupockets,
    buttonText: "Add To Cart",
    stars: Array(5).fill(<FaStar className="text-yellow-500" />),
    price: 7.99,
    originalPrice: "$7.99",
    category: "Sushi",
    ingredients: [
      "Rice",
      "Tuna",
      "Salmon",
      "Spicy Sauce",
      "Fish Egg",
      "Green onion",
    ],
  },
  {
    id: 6,
    name: "Philly Roll",
    image: phillyroll,
    buttonText: "Add To Cart",
    stars: Array(3).fill(<FaStar className="text-yellow-500" />),
    price: 8.59,
    originalPrice: "$5.50",
    category: "Sushi",
    ingredients: ["Crab salad or salmon w/ avocado and cream cheese"],
    meats: [{ name: "salmon" }, { name: "crab salad" }],
  },
  {
    id: 7,
    name: "Sashimi",
    image: sashimi,
    buttonText: "Add To Cart",
    stars: Array(4).fill(<FaStar className="text-yellow-500" />),
    price: 14.99,
    originalPrice: "$12.99",
    category: "Sushi",
    ingredients: ["Raw salmon or tuna, shrimp", "Octopus"],
    meats: [
      { name: "Tuna" },
      { name: "salmon" },
      { name: "shrimp" },
      { name: "Eel" },
      { name: "Octopus" },
    ],
  },
  {
    id: 8,
    name: "Rainbow Roll",
    image: rainbowroll,
    buttonText: "Add To Cart",
    stars: Array(3).fill(<FaStar className="text-yellow-500" />),
    price: 12.99,
    originalPrice: "$8.99",
    category: "Sushi",
    ingredients: ["Crab salad", "Avocado", "Cucumber", "Tuna and Salmon"],
  },
  {
    id: 9,
    name: "Dragon Roll",
    image: dragonroll,
    buttonText: "Add To Cart",
    stars: Array(4).fill(<FaStar className="text-yellow-500" />),
    price: 12.79,
    originalPrice: "$22.99",
    category: "Sushi",
    ingredients: [
      "Crab salad or shrimp tempura and cucumber, Salmon, and tuna",
      "Avocado, Eel sauce and sesame seed on top",
    ],
    meats: [
      { name: "crab salad" },
      { name: "shrimp tempura" },
      { name: "Tuna" },
      { name: "Salmon" },
    ],
  },
  {
    id: 10,
    name: "Crunchy Roll",
    image: crunchyroll,
    buttonText: "Add To Cart",
    stars: Array(3).fill(<FaStar className="text-yellow-500" />),
    price: 11.99,
    originalPrice: "$7.99",
    category: "Sushi",
    ingredients: [
      "Crab salad",
      "or Shrimp tempura",
      "W/ avocado, spicy sauce, eel sauce, sriracha, and fried onion on top",
    ],
    meats: [
      { name: "Crab Salad" },
      { name: "Shrimp Tempura" },
      { name: "Salmon" },
      { name: "Tuna" },
    ],
  },
  {
    id: 11,
    name: "Spicy Roll",
    image: spicyroll,
    buttonText: "Add To Cart",
    stars: Array(4).fill(<FaStar className="text-yellow-500" />),
    price: 8.59,
    originalPrice: "$13.99",
    category: "Sushi",
    ingredients: [
      "Tuna",
      "Salmon",
      "Shrimp",
      "Crab Salad w/ avocado and spicy sauce",
      "eel sauce",
      "chili powder",
    ],
    meats: [
      { name: "Tuna" },
      { name: "salmon" },
      { name: "shrimp" },
      { name: "crab salad" },
    ],
    toppings: [
      { name: "Chili powder" },
      { name: "sesame seeds" },
      { name: "fried onion" },
    ],
  },
  {
    id: 12,
    name: "Spicy Mango Roll",
    image: spicymangoroll,
    buttonText: "Add To Cart",
    stars: Array(4).fill(<FaStar className="text-yellow-500" />),
    price: 12.99,
    originalPrice: "$11.99",
    category: "Sushi",
    ingredients: [
      "Cream cheese",
      "Avocado",
      "Cucumber",
      "Tuna, salmon, Shrimp, crab salad w/ eel sauce sriracha, mango & chili powder",
    ],
    meats: [
      { name: "Tuna" },
      { name: "salmon" },
      { name: "crab salad" },
      { name: "Shrimp" },
    ],
  },
  {
    id: 13,
    name: "Dynamite Roll",
    image: dynamiteroll,
    buttonText: "Add To Cart",
    stars: Array(4).fill(<FaStar className="text-yellow-500" />),
    price: 12.99,
    originalPrice: "$10.99",
    category: "Sushi",
    ingredients: [
      "shrimp tempura and avocado, tuna, salmon, spicy sauce, chili powder, fish egg, green onion",
    ],
  },
  {
    id: 14,
    name: "Poke Bowl",
    image: pokebowl,
    buttonText: "Add To Cart",
    stars: Array(4).fill(<FaStar className="text-yellow-500" />),
    price: 13.99,
    originalPrice: 6.99,
    category: "Sushi",
    ingredients: [
      "Rice or w/ spicy spicy sauce",
      "Eel sauce",
      "Cucumber",
      "Carrot",
      "Seaweed salad",
    ],
    meats: [{ name: "Tuna" }, { name: "salmon" }, { name: "shrimp" }],
    carb: [{ name: "Rice" }, { name: "Lettuce" }],
  },
  {
    id: 23,
    name: "Slushies",
    image: slushy,
    buttonText: "Add To Cart",
    stars: Array(4).fill(<FaStar className="text-yellow-500" />),
    price: 7.19,
    originalPrice: "$7.19",
    category: "Slushies",
    subCat: 'Boba',
    // ingredients: [
    //   "Matcha green tea",
    //   "Cream",
    //   "Sugar",
    //   "Egg yolks",
    //   "Vanilla extract",
    // ],
    // base: [{name: 'Oolong Tea'},{name: 'Black Tea'}, {name: 'Green Tea'}],
    flavors: [
      { name: "Almond" },
      { name: "Banana" },
      { name: "Blueberry" },
      { name: "Brown Sugar" },
      { name: "Cappuccino" },
      { name: "Chai Tea" },
      { name: "Coconut" },
      { name: "Coffee Latte" },
      { name: "Coffee Mocha" },
      { name: "Honeydew" },
      { name: "Mango" },
      { name: "Matcha" },
      { name: "Origin" },
      { name: "Peach" },
      { name: "Pineapple" },
      { name: "Strawberry" },
      { name: "Taro" },
      { name: "Thai Tea" },
      { name: "Vanilla" },
      { name: "Watermelon" },
    ],
    toppings: [
      { name: "Tapioca Pearl" },
      { name: "Blueberry" },
      { name: "Mango" },
      { name: "Kiwi" },
      { name: "Lychee" },
      { name: "Passion Fruit" },
      { name: "Peach" },
      { name: "Strawberry" },
      { name: "Rainbow Boba" },
      { name: "Rainbow Jelly" },
      { name: "Lychee Jelly" },
      { name: "Coffee Jelly" },
    ],
    toppingsExtra: [
      { name: "Tapioca Pearl", price: 0.65 },
      { name: "Blueberry", price: 0.65 },
      { name: "Mango", price: 0.65 },
      { name: "Kiwi", price: 0.65 },
      { name: "Lychee", price: 0.65 },
      { name: "Passion Fruit", price: 0.65 },
      { name: "Peach", price: 0.65 },
      { name: "Strawberry", price: 0.65 },
      { name: "Rainbow Boba", price: 0.65 },
      { name: "Rainbow Jelly", price: 0.65 },
      { name: "Lychee Jelly", price: 0.65 },
      { name: "Coffee Jelly", price: 0.65 },
    ],
  },
  {
    id: 61,
    name: "Thai beef noodle soup",
    image: noodlesoup,
    buttonText: "Add To Cart",
    stars: Array(4).fill(<FaStar className="text-yellow-500" />),
    price: 12.99,
    // sizes: ['Reg', 'Lg'],
    originalPrice: "$7.19",
    category: "Soup",
    ingredients: [
      "Rice stick noodle",
      "Beef",
      "Meatball",
      "Bok choy",
      "Sprout",
      "Cilantro",
      "Thai Basil",
    ],
    // meats: [{ name: "Beef" }, { name: "Chicken" }],
    spices: [{ name: "mild" }, { name: "Hot" }, { name: "Super Hot" }],
  },
  {
    id: 62,
    name: "Thai basil stir fry",
    image: thaibasilstirfry,
    buttonText: "Add To Cart",
    stars: Array(4).fill(<FaStar className="text-yellow-500" />),
    price: 12.99,
    // sizes: ['Reg', 'Lg'],
    originalPrice: "$7.19",
    category: "Grill & Stir fry",
    ingredients: [
      "Rice",
      "Beef",
      "Cilantro",
      "Thai Basil",
      "Bell pepper",
      "Onion",
      "Jalapeno",
    ],
    meats: [{ name: "Chicken" }, { name: "Beef" }],
    spices: [{ name: "mild" }, { name: "hot" }, { name: "super hot" }],
  },
  {
    id: 63,
    name: "Coconut Chicken Noodle Soup (Only Friday and Saturday)",
    image: coconutchickennoodlesoup,
    buttonText: "Add To Cart",
    stars: Array(4).fill(<FaStar className="text-yellow-500" />),
    price: 12.99,
    // sizes: ['Reg', 'Lg'],
    originalPrice: "$7.19",
    category: "Soup",
    ingredients: [
      "Egg noodles",
      "cabbage",
      "Cilantro",
      "Onion",
      "egg",
      "breaded fried bean",
    ],
    // meats: [{ name: "Chicken" }, { name: "Beef" }, { name: "Pork" }],
    spices: [{ name: "mild" }, { name: "hot" }, { name: "super hot" }],
  },
  {
    id: 64,
    name: "Teriyaki Bowl",
    image: teriyakibowl,
    buttonText: "Add To Cart",
    stars: Array(4).fill(<FaStar className="text-yellow-500" />),
    price: 12.99,
    // sizes: ['Reg', 'Lg'],
    originalPrice: "$7.19",
    category: "Grill & Stir fry",
    ingredients: ["Rice", "Beef"],
    carb: [{ name: "Rice" }, { name: "Noodle" }],
    meats: [{ name: "Chicken" }, { name: "Beef" }],
    // spices: [{ name: "mild" }, { name: "hot" }, { name: "super hot" }],
  },
  {
    id: 65,
    name: "Deep Fried Vegetable Spring Roll (4pcs)",
    image: vegetablespringroll,
    buttonText: "Add To Cart",
    stars: Array(4).fill(<FaStar className="text-yellow-500" />),
    price: 5,
    // sizes: ['Reg', 'Lg'],
    originalPrice: "$7.19",
    category: "Deep Fried",
    ingredients: ["Cabbage", "carrot", "onion", "Comes with sweet chili sauce"],

    // meats: [{ name: "Chicken" }, { name: "Beef" }, { name: "Pork" }],
    // spices: [{ name: "mild" }, { name: "hot" }, { name: "super hot" }],
  },
  {
    id: 66,
    name: "Veggie Tempura",
    image: veggietempura,
    buttonText: "Add To Cart",
    stars: Array(4).fill(<FaStar className="text-yellow-500" />),
    price: 7.99,
    // sizes: ['Reg', 'Lg'],
    originalPrice: "$7.19",
    category: "Deep Fried",
    ingredients: ["Broccoli", "Squash", "Onion", "Bred bell pepper", "Served with sweet chili sauce"],
    // meats: [{ name: "Chicken" }, { name: "Beef" }, { name: "Pork" }],
    // spices: [{ name: "mild" }, { name: "hot" }, { name: "super hot" }],
  },
  {
    id: 67,
    name: "Bowl of Rice",
    image: noImage,
    buttonText: "Add To Cart",
    stars: Array(4).fill(<FaStar className="text-yellow-500" />),
    price: 3,
    // sizes: ['Reg', 'Lg'],
    originalPrice: "$7.19",
    category: "Extra",
    // ingredients: [
    //   "Rice",
    //   "Beef",
    //   "Cilantro",
    //   "Thai Basil",
    //   "Bell pepper",
    //   "Onion",
    //   "Jalapeno",
    // ],
    // meats: [{ name: "Chicken" }, { name: "Beef" }, { name: "Pork" }],
    // spices: [{ name: "mild" }, { name: "hot" }, { name: "super hot" }],
  },
  {
    id: 68,
    name: "Seaweed Salad",
    image: noImage,
    buttonText: "Add To Cart",
    stars: Array(4).fill(<FaStar className="text-yellow-500" />),
    price: 4.99,
    // sizes: ['Reg', 'Lg'],
    originalPrice: "$7.19",
    category: "Extra",
    // ingredients: [
    //   "Rice",
    //   "Beef",
    //   "Cilantro",
    //   "Thai Basil",
    //   "Bell pepper",
    //   "Onion",
    //   "Jalapeno",
    // ],
    // meats: [{ name: "Chicken" }, { name: "Beef" }, { name: "Pork" }],
    // spices: [{ name: "mild" }, { name: "hot" }, { name: "super hot" }],
  },
  {
    id: 69,
    name: "Edamame with Sea Salt",
    image: noImage,
    buttonText: "Add To Cart",
    stars: Array(4).fill(<FaStar className="text-yellow-500" />),
    price: 4.99,
    // sizes: ['Reg', 'Lg'],
    originalPrice: "$7.19",
    category: "Extra",
    // ingredients: [
    //   "Rice",
    //   "Beef",
    //   "Cilantro",
    //   "Thai Basil",
    //   "Bell pepper",
    //   "Onion",
    //   "Jalapeno",
    // ],
    // meats: [{ name: "Chicken" }, { name: "Beef" }, { name: "Pork" }],
    // spices: [{ name: "mild" }, { name: "hot" }, { name: "super hot" }],
  },
  {
    id: 70,
    name: "Spring Roll",
    image: springroll,
    buttonText: "Add To Cart",
    stars: Array(4).fill(<FaStar className="text-yellow-500" />),
    prices: [6.99, 10.99],
    sizes: ["2 pcs", "4 pcs"],
    originalPrice: "$7.19",
    category: "Sushi",
    ingredients: [
      "Shrimp, tuna or salmon",
      "Rice Paper",
      "Lettuce",
      "Carrot",
      "Avocado",
      "Cucumber ",
      "Crab Salad",
    ],
    meats: [{ name: "Shrimp" }, { name: "Tuna" }, { name: "Salmon" }],
    toppings: [{ name: "Sweet chili sauce" }, { name: "Peanut" }],
    // spices: [
    //   { name: "mild"},
    //   { name: "hot"},
    //   { name: "super hot"},
    // ],
  },
  {
    id: 71,
    name: "Lavender Lemonade",
    image: lavenderlemonade,
    buttonText: "Add To Cart",
    stars: Array(4).fill(<FaStar className="text-yellow-500" />),
    price: 5.79,
    // sizes: ['Reg', 'Lg'],
    originalPrice: "$7.19",
    category: "Other Drinks",
    // ingredients: [
    //   "Rice",
    //   "Beef",
    //   "Cilantro",
    //   "Thai Basil",
    //   "Bell pepper",
    //   "Onion",
    //   "Jalapeno",
    // ],
    // meats: [{ name: "Chicken" }, { name: "Beef" }, { name: "Pork" }],
    // spices: [{ name: "mild" }, { name: "hot" }, { name: "super hot" }],
  },
  {
    id: 72,
    name: "Vietnamese Iced Coffee",
    image: vietnameseicedcoffee,
    buttonText: "Add To Cart",
    stars: Array(4).fill(<FaStar className="text-yellow-500" />),
    price: 6.5,
    // sizes: ['Reg', 'Lg'],
    originalPrice: "$7.19",
    category: "Other Drinks",
    // ingredients: [
    //   "Rice",
    //   "Beef",
    //   "Cilantro",
    //   "Thai Basil",
    //   "Bell pepper",
    //   "Onion",
    //   "Jalapeno",
    // ],
    // meats: [{ name: "Chicken" }, { name: "Beef" }, { name: "Pork" }],
    // spices: [{ name: "mild" }, { name: "hot" }, { name: "super hot" }],
  },
  {
    id: 73,
    name: "Fruit Teas",
    image: fruitteas,
    buttonText: "Add To Cart",
    stars: Array(4).fill(<FaStar className="text-yellow-500" />),
    prices: [4.79, 5.79],
    sizes: ["Reg", "Lg"],
    originalPrice: "$7.19",
    category: "Fruit Teas",
    subCat: 'Boba',
    // ingredients: [
    //   "Matcha green tea",
    //   "Cream",
    //   "Sugar",
    //   "Egg yolks",
    //   "Vanilla extract",
    // ],
    base: [
      { name: "Water" },
      { name: "Oolong Tea" },
      { name: "Black Tea" },
      { name: "Green Tea" },
    ],
    flavors: [
      { name: "Blueberry" },
      { name: "Kiwi" },
      { name: "Lychee" },
      { name: "Mango" },
      { name: "Passion Fruit" },
      { name: "Peach" },
      { name: "Pineapple" },
      { name: "Rasberry" },
      { name: "Strawberry" },
    ],
    toppings: [
      { name: "Tapioca Pearl" },
      { name: "Blueberry" },
      { name: "Mango" },
      { name: "Kiwi" },
      { name: "Lychee" },
      { name: "Passion Fruit" },
      { name: "Peach" },
      { name: "Strawberry" },
      { name: "Rainbow Boba" },
      { name: "Rainbow Jelly" },
      { name: "Lychee Jelly" },
      { name: "Coffee Jelly" },
    ],
    toppingsExtra: [
      { name: "Tapioca Pearl", price: 0.65 },
      { name: "Blueberry", price: 0.65 },
      { name: "Mango", price: 0.65 },
      { name: "Kiwi", price: 0.65 },
      { name: "Lychee", price: 0.65 },
      { name: "Passion Fruit", price: 0.65 },
      { name: "Peach", price: 0.65 },
      { name: "Strawberry", price: 0.65 },
      { name: "Rainbow Boba", price: 0.65 },
      { name: "Rainbow Jelly", price: 0.65 },
      { name: "Lychee Jelly", price: 0.65 },
      { name: "Coffee Jelly", price: 0.65 },
    ],
  },

  {
    id: 85,
    name: "Smoothies",
    image: smoothies,
    buttonText: "Add To Cart",
    stars: Array(4).fill(<FaStar className="text-yellow-500" />),
    price: 7.79,
    // sizes: ["Reg", "Lg"],
    originalPrice: "$7.19",
    category: "Smoothies",
    subCat: 'Boba',
    // ingredients: [
    //   "Matcha green tea",
    //   "Cream",
    //   "Sugar",
    //   "Egg yolks",
    //   "Vanilla extract",
    // ],
    flavors: [
      { name: "Avocado" },
      { name: "Mango" },
      { name: "Peach" },
      { name: "Pineapple" },
    ],
    toppings: [
      { name: "Tapioca Pearl" },
      { name: "Blueberry" },
      { name: "Mango" },
      { name: "Kiwi" },
      { name: "Lychee" },
      { name: "Passion Fruit" },
      { name: "Peach" },
      { name: "Strawberry" },
      { name: "Rainbow Boba" },
      { name: "Rainbow Jelly" },
      { name: "Lychee Jelly" },
      { name: "Coffee Jelly" },
    ],
    toppingsExtra: [
      { name: "Tapioca Pearl", price: 0.65 },
      { name: "Blueberry", price: 0.65 },
      { name: "Mango", price: 0.65 },
      { name: "Kiwi", price: 0.65 },
      { name: "Lychee", price: 0.65 },
      { name: "Passion Fruit", price: 0.65 },
      { name: "Peach", price: 0.65 },
      { name: "Strawberry", price: 0.65 },
      { name: "Rainbow Boba", price: 0.65 },
      { name: "Rainbow Jelly", price: 0.65 },
      { name: "Lychee Jelly", price: 0.65 },
      { name: "Coffee Jelly", price: 0.65 },
    ],
  },
  {
    id: 89,
    name: "Milk Teas",
    image: milkteas,
    buttonText: "Add To Cart",
    stars: Array(4).fill(<FaStar className="text-yellow-500" />),
    prices: [5.79, 6.79],
    sizes: ["Reg", "Lg"],
    originalPrice: "$7.19",
    category: "Milk Teas",
    subCat: 'Boba',
    // ingredients: [
    //   "Matcha green tea",
    //   "Cream",
    //   "Sugar",
    //   "Egg yolks",
    //   "Vanilla extract",
    // ],
    base: [
      { name: "Water" },
      { name: "Oolong Tea" },
      { name: "Black Tea" },
      { name: "Green Tea" },
    ],
    flavors: [
      { name: "Almond" },
      { name: "Banana" },
      { name: "Blueberry" },
      { name: "Brown Sugar" },
      { name: "Cappuccino" },
      { name: "Chai Tea" },
      { name: "Coconut" },
      { name: "Coffee Latte" },
      { name: "Coffee Mocha" },
      { name: "Honeydew" },
      { name: "Mango" },
      { name: "Matcha" },
      { name: "Origin" },
      { name: "Peach" },
      { name: "Pineapple" },
      { name: "Strawberry" },
      { name: "Taro" },
      { name: "Thai Tea" },
      { name: "Vanilla" },
      { name: "Watermelon" },
    ],
    toppings: [
      { name: "Tapioca Pearl" },
      { name: "Blueberry" },
      { name: "Mango" },
      { name: "Kiwi" },
      { name: "Lychee" },
      { name: "Passion Fruit" },
      { name: "Peach" },
      { name: "Strawberry" },
      { name: "Rainbow Boba" },
      { name: "Rainbow Jelly" },
      { name: "Lychee Jelly" },
      { name: "Coffee Jelly" },
    ],
    toppingsExtra: [
      { name: "Tapioca Pearl", price: 0.65 },
      { name: "Blueberry", price: 0.65 },
      { name: "Mango", price: 0.65 },
      { name: "Kiwi", price: 0.65 },
      { name: "Lychee", price: 0.65 },
      { name: "Passion Fruit", price: 0.65 },
      { name: "Peach", price: 0.65 },
      { name: "Strawberry", price: 0.65 },
      { name: "Rainbow Boba", price: 0.65 },
      { name: "Rainbow Jelly", price: 0.65 },
      { name: "Lychee Jelly", price: 0.65 },
      { name: "Coffee Jelly", price: 0.65 },
    ],
  },
  {
    id: 90,
    name: "Soft Drinks",
    image:
      "https://t4.ftcdn.net/jpg/03/49/80/15/360_F_349801592_MX0ZnIl6pXNQH4srBoJEhJCSxlhUTDpf.jpg",
    buttonText: "Add To Cart",
    stars: Array(4).fill(<FaStar className="text-yellow-500" />),
    // prices: [5.79, 6.79],
    // sizes: ["Reg", "Lg"],
    originalPrice: "$7.19",
    category: "Soft Drinks",
    // ingredients: [
    //   "Matcha green tea",
    //   "Cream",
    //   "Sugar",
    //   "Egg yolks",
    //   "Vanilla extract",
    // ],

    toppingsExtra: [
      { name: "Coke 12 fl Oz", price: 1.69 },
      { name: "Sprite", price: 1.69 },
      { name: "Dr Pepper", price: 1.69 },
      { name: "Diet Coke", price: 1.69 },
      { name: "Sunny D", price: 1.89 },
      { name: "Mountain Dew", price: 1.69 },
      { name: "Red Bull 8.4 fl Oz", price: 3.49 },
      { name: "Prime (Strawberry Watermelon) 16.9 fl Oz", price: 3.19 },
      { name: "Prime Lemonade 16.9 fl Oz", price: 3.19 },
    ],

    drinks: [
      { name: "Coke 12 fl Oz", price: 1.69 },
      { name: "Sprite", price: 1.69 },
      { name: "Dr Pepper", price: 1.69 },
      { name: "Diet Coke", price: 1.69 },
      { name: "Sunny D", price: 1.89 },
      { name: "Mountain Dew", price: 1.69 },
      { name: "Red Bull 8.4 fl Oz", price: 3.49 },
      { name: "Prime (Strawberry Watermelon) 16.9 fl Oz", price: 3.19 },
      { name: "Prime Lemonade 16.9 fl Oz", price: 3.19 },
    ],
  },
  {
    id: 91,
    name: "Frence Fries",
    image:
      "https://images.pexels.com/photos/6941039/pexels-photo-6941039.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    buttonText: "Add To Cart",
    stars: Array(4).fill(<FaStar className="text-yellow-500" />),
    price: 4.59,
    // sizes: ["Reg", "Lg"],
    originalPrice: "$7.19",
    category: "Deep Fried",
    // ingredients: [
    //   "Matcha green tea",
    //   "Cream",
    //   "Sugar",
    //   "Egg yolks",
    //   "Vanilla extract",
    // ],

    // toppingsExtra: [
    //   { name: "Coke 12 fl Oz", price: 1.69 },
    //   { name: "Sprite", price: 1.69 },
    //   { name: "Dr Pepper", price: 1.69 },
    //   { name: "Diet Coke", price: 1.69 },
    //   { name: "Mountain Dew", price: 1.69 },
    //   { name: "Red Bull 8.4 fl Oz", price: 3.49 },
    //   { name: "Prime (Strawberry Watermelon) 16.9 fl Oz", price: 3.19 },
    //   { name: "Prime Lemonade 16.9 fl Oz", price: 3.19 },
    // ],

    // drinks: [
    //   { name: "Coke 12 fl Oz", price: 1.69 },
    //   { name: "Sprite", price: 1.69 },
    //   { name: "Dr Pepper", price: 1.69 },
    //   { name: "Diet Coke", price: 1.69 },
    //   { name: "Mountain Dew", price: 1.69 },
    //   { name: "Red Bull 8.4 fl Oz", price: 3.49 },
    //   { name: "Prime (Strawberry Watermelon) 16.9 fl Oz", price: 3.19 },
    //   { name: "Prime Lemonade 16.9 fl Oz", price: 3.19 },
    // ],
  },
];

export default items;
