import { createContext, useEffect, useState } from "react";
import { Home } from "./pages/Home";
import axios from 'axios';
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom"; // Import Navigate
import { AboutUs } from "./pages/AboutUs";
import { Menu } from "./pages/Menu";
import { Contact } from "./pages/Contact";
import { Navbar } from "./components/Navbar";
import { Footer } from "./components/Footer/Footer";
import { ReviewsPage } from "./pages/ReviewsPage";
import { Spinner } from "./components/spinner/Spinner";
import { IngredientPage } from "./pages/IngredientPage";
import { Admin } from "./components/Admin/Admin";
import { toast } from "react-toastify";
import { Success } from "./pages/Success";
import { Cancel } from "./pages/Cancel";
import { Cart } from "./components/Cart/Cart";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ResetPass } from "./pages/ResetPass";
import { OwnerView } from "./pages/OwnerView";
import NotFound from "./pages/NotFound";
import { UnderConstruction } from "./pages/UnderConstruction"; // Import the UnderConstruction page
import GoogleReviews from "./pages/GoogleReviews";

export const AppProvider = createContext();

function App() {
  const [cart, setCart] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);
  const [cartChanged, setCartChanged] = useState(false);
  const [underConstruction, setUnderConstruction] = useState(false); // Default to false for testing
  const [logOrSign, setLogOrSign] = useState(false);
  const [hideNav, setHideNav] = useState(false);
  const [hideFooter, setHideFooter] = useState(false);
  const [iconClicked, setIconClicked] = useState(false);
  const [userLogged, setUserLogged] = useState([]);
  const [fav, setFav] = useState([]);
  const [webSocketMessage, setWebSocketMessage] = useState("");
  const [cartItems, setCartItems] = useState([]);
  const [hearts, setHearts] = useState([]);
  const url = localStorage.getItem("url");
  const environment = process.env.NODE_ENV;

  useEffect(() => {
    // Simulate loading for 2 seconds
    const timer = setTimeout(() => {
      setShowSpinner(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const getUser = async () => {
      try {
        const user = await axios.get(`${url}api/user`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    getUser();

    if (environment === "development") {
      localStorage.setItem("url", "http://localhost:3001/");
    }
    if (environment === "production") {
      localStorage.setItem("url", "https://sushiboba.onrender.com/");
    }

    if (window.location.pathname === '/ownerview') {
      setHideNav(true);
    } else {
      setHideNav(false);
    }
  }, []);

  // const addItem = async (item, user) => {
  //   if (!localStorage.getItem("logged")) {
  //     toast.error("Please Login!");
  //   }

  //   try {
  //     const response = await axios.post(`${url}api/cart`, {
  //       item: item,
  //       current_user: localStorage.getItem("logged"),
  //     });

  //     if (response.data.error) {
  //       toast.error(response.data.message);
  //     } else {
  //       toast.success(response.data.message);
  //     }
  //     const newItem = response.data;
  //     setCartItems((prevItems) => [...prevItems, newItem]);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const addItem = async (item) => {
    const userOrGuest = localStorage.getItem("logged") || localStorage.getItem("guestId");
    if (!userOrGuest) {
        toast.error("Unable to identify user or guest. Please try again.");
        return;
    }

    try {
        const response = await axios.post(`${url}api/cart`, {
            item: item,
            current_user: userOrGuest, // Use guestId if user is not logged in
        });

        if (response.data.error) {
            toast.error(response.data.message);
        } 

        const newItem = response.data;
        setCartItems((prevItems) => [...prevItems, newItem]);
    } catch (error) {
        console.error(error);
    }
};


  const increaseItem = async (id) => {
    try {
      const response = await axios.put(`${url}api/cart/increase/${id}`);
      const updatedItem = response.data;
      const updatedCartItems = cartItems.map((item) =>
        item._id === updatedItem._id ? updatedItem : item
      );
      setCartItems(updatedCartItems);
    } catch (error) {
      console.error(error);
    }
  };

  const decreaseItem = async (id) => {
    try {
      const response = await axios.put(`${url}api/cart/decrease/${id}`);
      const updatedItem = response.data;

      if (updatedItem.quantity <= 0) {
        await deletItem(id);
      } else {
        const updatedCartItems = cartItems.map((item) =>
          item._id === updatedItem._id ? updatedItem : item
        );
        setCartItems(updatedCartItems);
      }
    } catch (error) {
      console.error("Error decreasing item quantity:", error);
    }
  };

  const deletItem = async (id) => {
    try {
      await axios.delete(`${url}api/cart/${id}`);
      const updatedCartItems = cartItems.filter((item) => item._id !== id);
      setCartItems(updatedCartItems);
    } catch (error) {
      console.error(error);
    }
  };

  const clearCart = async (email) => {
    try {
      const response = await axios.delete(`${url}api/cart/clear/${email}`);
      console.log(response);
    } catch (error) {
      console.error("Error clearing cart:", error);
    }
  };

  const addFav = async (item, user) => {
    if (!localStorage.getItem("logged")) {
      toast.error("Please Login!");
    }

    console.log(item, user);
    try {
      const response = await axios.post(`${url}api/fav`, {
        item: item,
        current_user: user,
      });

      const newItem = response.data;
      setFav((prevItems) => [...prevItems, newItem]);
    } catch (error) {
      console.error(error);
    }
  };

  const increaseFavItem = async (id) => {
    try {
      const response = await axios.put(`${url}api/fav/increase/${id}`);
      const updatedItem = response.data;
      const updatedFavItems = fav.map((item) =>
        item._id === updatedItem._id ? updatedItem : item
      );
      setFav(updatedFavItems);
    } catch (error) {
      console.error(error);
    }
  };

  const decreaseFavItem = async (id) => {
    try {
      const response = await axios.put(`${url}api/fav/decrease/${id}`);
      const updatedItem = response.data;

      if (updatedItem.quantity <= 0) {
        await deletFavItem(id);
      } else {
        const updatedFavItems = fav.map((item) =>
          item._id === updatedItem._id ? updatedItem : item
        );
        setFav(updatedFavItems);
      }
    } catch (error) {
      console.error("Error decreasing item quantity:", error);
    }
  };

  const deletFavItem = async (id) => {
    try {
      await axios.delete(`${url}api/fav/${id}`);
      const updatedFavItems = fav.filter((item) => item._id !== id);
      setFav(updatedFavItems);
    } catch (error) {
      console.error(error);
    }
  };

  const clearFav = async (email) => {
    try {
      const response = await axios.delete(`${url}api/fav/clear/${email}`);
      console.log(response);
    } catch (error) {
      console.error("Error clearing cart:", error);
    }
  };

  const values = {
    iconClicked,
    setIconClicked,
    fav,
    addFav,
    increaseFavItem,
    decreaseFavItem,
    deletFavItem,
    clearFav,
    userLogged,
    hearts,
    setHearts,
    cartItems,
    addItem,
    increaseItem,
    decreaseItem,
    clearCart,
    deletItem,
    setLogOrSign,
    logOrSign,
    cartChanged,
    setCartChanged,
    url,
    toast,
    isSidebarOpen,
    setIsSidebarOpen,
    webSocketMessage,
    setWebSocketMessage,
  };

  return (
    <AppProvider.Provider value={values}>
      <ToastContainer />
      <BrowserRouter>
        {hideNav ? '' : <Navbar />}
        {showSpinner ? (
          <Spinner />
        ) : (
          underConstruction ? (
            <UnderConstruction/>
          ) : (
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/menu" element={<Menu />} />
              <Route path="/success" element={<Success />} />
              <Route path="/request-reset-password" element={<ResetPass />} />
              <Route path="/owner-view" element={<OwnerView />} />
              <Route path="/cart" element={<Cart />} />
              <Route path="/cancel" element={<Cancel />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/googleReviews" element={<GoogleReviews />} />
              <Route path="/reviews" element={<ReviewsPage />} />
              <Route path="/admin" element={<Admin />} />
              <Route path="/ingredientPage/:id" element={<IngredientPage />} />
              <Route path="/under-construction" element={<UnderConstruction />} /> {/* Add route for under construction */}
              <Route path="*" element={<NotFound />} />
            </Routes>
          )
        )}
        {hideFooter ? '' : <Footer />}
      </BrowserRouter>
    </AppProvider.Provider>
  );
}

export default App;
