import React, { useContext, useEffect, useState } from "react";
import { RiMenu2Line } from "react-icons/ri";
import { RxAvatar } from "react-icons/rx";
import { AppProvider } from "../App";
import logo from "../assets/2020logos.png";
import { Sidebar } from "./SideBar/Sidebar";
import { IoBagOutline } from "react-icons/io5";
import { IoExitOutline } from "react-icons/io5";
import axios from "axios";
import { format } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import items from "../components/Trending/TrendingData";
import { CiSearch } from "react-icons/ci";

export const Navbar = () => {
  const { cart, url, cartChanged, increaseItem, decreaseItem, deletItem } =
    useContext(AppProvider);
  const [scrolled, setScrolled] = useState(false);
  const [showLogOut, setShowLogOut] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const { isSidebarOpen, setIsSidebarOpen } = useContext(AppProvider);
  const [isAdmin, setIsAdmin] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [storeStatus, setStoreStatus] = useState("");
  // const loggedUser = localStorage.getItem("logged");
  const guest = localStorage.getItem("guestId");
  const token = localStorage.getItem("token");

  const [query, setQuery] = useState("");

  // Filter items based on the search query
  const filteredItems = items.filter((item) =>
    item.category.toLowerCase().includes(query.toLowerCase()) || item.name.toLowerCase().includes(query.toLowerCase()) || item.subCat?.toLowerCase().includes(query.toLowerCase())
  );

  // Check if the user is logged in
  const loggedUser =
    localStorage.getItem("logged") || localStorage.getItem("guestId");

  // Store Hours
  const storeHours = {
    Sunday: null,
    Monday: { open: "11:00", close: "19:30" },
    Tuesday: { open: "11:00", close: "19:30" },
    Wednesday: { open: "11:00", close: "19:30" },
    Thursday: { open: "11:00", close: "19:30" },
    Friday: { open: "11:00", close: "19:30" },
    Saturday: { open: "11:00", close: "19:30" },
  };

  const timeZone = "America/Denver"; // Utah's time zone

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const utahTime = toZonedTime(now, timeZone);
      setCurrentTime(utahTime);

      const today = format(utahTime, "EEEE"); // Get current day of the week
      const hours = storeHours[today];

      if (hours) {
        const currentTimeStr = format(utahTime, "HH:mm");

        // Calculate 30 minutes before closing time
        const closingTime = hours.close;
        const closingSoonTime = new Date(
          utahTime.setHours(
            ...closingTime
              .split(":")
              .map((time, idx) => (idx === 0 ? +time : +time - 30))
          )
        );

        // Store status logic
        if (currentTimeStr < hours.open) {
          setStoreStatus(`Opens at ${hours.open}`);
        } else if (
          currentTimeStr >= hours.open &&
          currentTimeStr < closingSoonTime
        ) {
          setStoreStatus("Open Today");
        } else if (
          currentTimeStr >= closingSoonTime &&
          currentTimeStr < hours.close
        ) {
          setStoreStatus("Closing Soon");
        } else {
          setStoreStatus("Closed");
        }
      } else {
        setStoreStatus("Closed");
      }
    }, 1000); // Update time every second

    return () => clearInterval(interval);
  }, []);

  // Handle fetch and user role
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (loggedUser) {
          const response = await axios.get(`${url}api/cart/${loggedUser}`);
          setCartItems(response.data || []);
        } else {
          const guestCart = JSON.parse(localStorage.getItem("guestCart")) || [];
          setCartItems(guestCart);
        }
      } catch {
        setCartItems([]);
      }
    };

    const checkUserRole = async () => {
      try {
        const response = await axios.get(`${url}api/one-user/${loggedUser}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setIsAdmin(response.data.role === "admin");
      } catch {
        setIsAdmin(false);
      }
    };

    checkUserRole();
    fetchData();
  }, [
    cartChanged,
    increaseItem,
    decreaseItem,
    deletItem,
    guest,
    url,
    token,
    loggedUser,
  ]);

  useEffect(() => {
    const handleScroll = () => setScrolled(window.scrollY > 50);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="z-[8000] sticky top-0 left-0 w-full bg-white drop-shadow-sm">
      {/* Top bar for date and status */}
      <div className="md:flex items-center justify-between px-6 py-2 bg-orange-500 text-white text-sm md:text-xl">
        <p className="font-bold">{format(currentTime, "EEEE, MMMM d, yyyy")}</p>
        {/* <p>{format(currentTime, "h:mm:ss a")} - {storeStatus}</p> */}
        <p>We are open 11AM - 7:30 PM</p>
      </div>

      <nav className="relative p-4 w-full px-6 h-24 flex justify-between items-center max-w-7xl mx-auto transition-all duration-300 ease-linear">
        <a href="/" className="font-bold text-5xl">
          <img
            className={"w-32 h-full transition-all duration-300 ease-linear"}
            src={logo}
            alt=""
          />
        </a>
        <ul className="hidden text-gray-500 lg:flex items-center justify-center space-x-6 text-xl py-4 mx-6">
          <li className="hover:text-orange-500">
            <a href="/">HOME</a>
          </li>
          <li className="hover:text-orange-500">
            <a href="/menu">MENU</a>
          </li>
          <li className="hover:text-orange-500">
            <a href="/about">ABOUT</a>
          </li>
          <li className="hover:text-orange-500">
            <a href="/contact">CONTACT</a>
          </li>
          <li className="hover:text-orange-500">
            <a href="/reviews">REVIEWS</a>
          </li>
        </ul>
        <div className="flex items-center space-x-4 text-3xl">
          {/* <div className="relative border-2  border-gray-100 flex items-center px-2 rounded-full">
            <input
              className="text-sm p-2 bg-transparent outline-none"
              type="text"
              value={query}
              onChange={(e) => {
                setQuery(e.target.value)
                setShowDropDown(!showDropDown)
              }}
              placeholder="Search by category, name..."
            />
            <CiSearch className="text-xl" />
          </div> */}
            {/* <div className={showDropDown ? "absolute grid md:grid-cols-2 lg:grid-cols-3 z-[10000] rounded -left-4 md:left-0 top-24 w-full overflow-y-scroll h-[500px] bg-white border border-orange-500" : "hidden"}>
              <p>coming soon!</p>
              {filteredItems.length > 0 ? (
                filteredItems.map((item) => (
                  <div key={item.id} className="p-4 border rounded shadow">
                    <img
                      src={item.image}
                      alt={item.name}
                      className="w-full h-48 object-cover rounded"
                    />
                    <h2 className="text-lg font-bold mt-2">{item.name}</h2>
                    <p className="text-sm text-gray-600">{item.category}</p>

                    <p className="text-green-500 font-bold">${item.price}</p>
                    <button className="bg-orange-500 text-white px-4 py-2 rounded mt-2">
                      {item.buttonText}
                    </button>
                  </div>
                ))
              ) : (
                <p className="absolute z-[19000] text-center  top-12 overflow-y-scroll h-auto p-4 bg-white">No items found</p>
              )}
            </div> */}
          {localStorage.getItem("logged") ? (
            <div className="relative">
              <RxAvatar
                className="cursor-pointer"
                onClick={() => setShowLogOut(!showLogOut)}
              />
              {showLogOut && (
                <div className="absolute -left-20 flex flex-col gap-4 p-2 bg-white rounded text-lg w-40">
                  <a
                    className="hover:text-orange-500 flex items-center"
                    href="/"
                    onClick={() => localStorage.removeItem("logged")}
                  >
                    <IoExitOutline />
                    <p>Logout</p>
                  </a>
                  {isAdmin && (
                    <a className="hover:text-orange-500" href="/owner-view">
                      Owner View
                    </a>
                  )}
                </div>
              )}
            </div>
          ) : (
            <a
              className="border px-4 py-3 rounded text-lg hover:text-orange-500"
              href="/admin"
            >
              LOGIN
            </a>
          )}
          <a
            href="/contact"
            className="hidden md:block bg-orange-500 text-white px-4 py-3 rounded text-xl"
          >
            Contact Us
          </a>
          <div className="relative">
            <a href="/cart">
              <IoBagOutline />
              <div className="absolute top-0 -right-2 bg-orange-500 text-white text-sm rounded-full h-5 w-5 flex items-center justify-center">
                {cartItems.length}
              </div>
            </a>
          </div>
          <RiMenu2Line
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            className="lg:hidden text-2xl cursor-pointer"
          />
        </div>
      </nav>
      <Sidebar />
    </div>
  );
};
